exports.onRouteUpdate = ({ location }) => {
    if (conditionToLoadScript(location.pathname)) {
        loadScript();
    } else {
        unloadChatbot();
    }
};

function loadScript() {
    const existingScript = document.getElementById('admissions-script');
    if (!existingScript) {
        const script = document.createElement('script');
        script.id = 'admissions-script';
        script.src = 'https://ai.ocelotbot.com/embed/standard/ec388af5-1690-40aa-af8e-2887feef2757?ha=right&amp;hv=16px&amp;va=bottom&amp;vv=16px&amp;ts=0&amp;bs=calloutBubble&amp;av=0';
        script.async = true;
        document.body.appendChild(script);
    }
}

function unloadChatbot() {
    // Remove the script tag if it exists
    const existingScript = document.getElementById('admissions-script');
    if (existingScript) {
        document.body.removeChild(existingScript);
    }

    // Remove the chatbot div if it exists
    const chatbotDiv = document.getElementById('ocelot_ai');
    if (chatbotDiv) {
        chatbotDiv.parentNode.removeChild(chatbotDiv);
    }
}

function conditionToLoadScript(pathname) {
    // Load the script if pathname starts with '/admissions/'
    return pathname.startsWith('/admissions/');
}
